
#tags {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #db9c56;
}
.tag .tag-title {
    margin-top: 3px;
}
.tag .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #db9c56;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}