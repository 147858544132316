.home-link{
    position:fixed;
    width:60px;
    height:60px;
    bottom:30px;
    right:120px;
    background-color:#007bff;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.home-icon{
    margin-top:16px;
}

.home-link:hover {
    color:#FFF;
}