.rateSet .react-rater{
    font-size: 21px !important;
}
.rateSet-big .react-rater{
    font-size: 25px !important;
}

.breadcrumb {
    line-height: 0px !important;
    margin-bottom: 4px !important;
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }
   
  .iframe-container iframe {
     border: 0;
     height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
  }

.pakage_premium {
    background-color: #d4af37;
}
.pakage_standar {
    background-color: #32c8de;

}
.pakage_free {
    background-color: #a9cce3 ;
}
.pakage_genial {
    background-color:  #52be80 ;









}
/* Your CSS codes here */
.property-thumb-image{

}

.property-header {
    overflow: hidden;
    position: relative;
}
.property-header .listing-holder {
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out 0s;
    width: 100%;
    z-index: 10;
}

.property-header .badge-wrap {
    color: #fff;
    font-family: "BebasNeue",sans-serif;
    font-size: 1.2em;
    height: 100px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 100px;
}
.property-header .badge-wrap.new {
    font-size: 1.6em;
}
.property-header .badge-wrap span {
    display: block;
    height: 100%;
    line-height: 100px;
    position: absolute;
    transform: rotate(45deg) translate(0px, -25%);
    -webkit-transform: rotate(45deg) translate(0px, -25%);
    -ms-transform: rotate(45deg) translate(0px, -25%);
    width: 100%;
}
.property-header .badge-wrap:before {
    border-color: transparent;
    border-style: solid;
    border-width: 0 100px 100px 0;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
}
.property-header .badge-wrap.none, .property-header .badge-wrap.none:before {
    display: none;
    height: 0;
    width: 0;
}
.property-header .badge-wrap.new:before, .property-header .badge-wrap.badge-blue:before {
    border-color: transparent #00b4ff;
}
.property-header .badge-wrap.for-sale:before, .property-header .badge-wrap.badge-green:before {
    border-color: transparent #73ba5d;
}
.property-header .badge-wrap.for-rent:before, .property-header .badge-wrap.badge-orange:before {
    border-color: transparent #fc8652;
}
.property-header .badge-wrap.reduced:before, .property-header .badge-wrap.badge-red:before {
    border-color: transparent #fa565a;
}
.property-header .badge-wrap.sold:before, .property-header .badge-wrap.rented:before, .property-header .badge-wrap.badge-gray:before {
    border-color: transparent #7f8c8d;
}
.property-header .badge-wrap.on-show:before, .property-header .badge-wrap.badge-purple:before {
    border-color: transparent #9b59b6;
}

.property-contract-type {
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    left: -33px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 12px;
    transform: scale(1) rotate(-45deg) translate(0px);
    -webkit-transform: scale(1) rotate(-45deg) translate(0px);
    -ms-transform: scale(1) rotate(-45deg) translate(0px);
    width: 120px;
    z-index: 1;
}
.property-contract-type span {
    color: #ffffff;
}
.property-contract-type.sold {
    background: none repeat scroll 0 0 #e42f2f;
}
.property-contract-type.rent {
    background: none repeat scroll 0 0 #ff7430;
}
.property-contract-type.sale {
    background: none repeat scroll 0 0 #528D47;
}
.property-contract-type.both {
    background: none repeat scroll 0 0 #FF7F00;
}

.property-thumb-meta {
    bottom: 0;
    right: 0;
    position: absolute;
}
.property-thumb-meta .property-price {
    clear: both;
    color: #ffffff;
    float: right;
    font-size: 16px;
    padding: 2px 4px;
    background-color: #3498db;
}

.property-header a {
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.2s ease-in 0s;
    width: 100%;
    z-index: 200;
}
.property-header a:hover {
    background-color: rgba(0, 0, 0, 0.5);
    background-position: center center;
    background-repeat: no-repeat;
}

.property-utilities{
    /*clear: both;*/
}
.hot-tag{
    border: 0 none;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: 30px;
    width: 30px;
    line-height: 23px;
    position: absolute;
    right: 2px;
    text-align: center;
    top: -185px;
    z-index: 20;
    background-color: #ffffff;
    border: 3px solid #BADA55;
}
.hot-tag i{
    color: #ff7430
}

.info-title{
    padding-left: 0;
    text-align: right;
}
.info-data{
    padding-left: 0;
    text-align: left;
}
.info-price{
    font-weight: bold;
    font-size: 15px;
}
.signup{
    color: #358;
}
.signin{
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"]{
    position: relative !important;
    margin-left:0 !important;
}
#contact_for_price{
    float: left;
    margin: 0;
    padding: 0;
    width: 20px;
}
.contact_for_price_label{
    margin-top: 5px;
}
.form-error{
    margin: 0;
    padding: 4px 9px;
}
.featured-img{
    background: none repeat scroll 0 0 #aaa;
    height: 200px;
    width: 265px;
}
.featured-img .upload-button{
    background: none repeat scroll 0 0 #222;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 37px;
    opacity: 0.7;
    padding-top: 8px;
    position: absolute;
    text-align: center;
    top: 162px;
    width: 265px;
    z-index: 5;
}
.featured-img img{
    float: left;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 0;
}
.tag-input{
    height: 100px !important;
}
.author-info {
    background: none repeat scroll 0 0 #f8f8f8;
    border: 1px solid #e1e1e1;
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
}

.author-info .author-avatar {
    background: none repeat scroll 0 0 #fff;
    border: 1px solid #e1e1e1;
    left: 20px;
    padding: 5px 5px 0;
    position: absolute;
    top: -50px;
}

.author-detail-right {
    float: right;
    width: 78%;
}
.author-detail-right i {
    border: 1px solid #e1e1e1;
    font-size: 16px !important;
    padding: 10px 13px;
}
.author-detail-right .fa-map-marker {
    padding: 10px 16px;
}
.author-detail-right .fa-map-marker {
    padding: 10px 16px;
}
.author-detail-right .fa-phone {
    padding: 10px 14px;
}

.ad-detail-info {
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
}
.ad-details {
    float: right;
    line-height: 18px;
    width: calc(100% - 26px);
}


.ad-detail-half-box {
    background: none repeat scroll 0 0 #f8f8f8;
    border: 1px solid #e4e4e4;
    float: left;
    margin-bottom: 30px;
    margin-top: 10px;
    position: relative;
    width: 100%;
}
.ad-detail-half-box .ad-detail-info {
    background: none repeat scroll 0 0 #f6f6f6;
    color: #666;
    line-height: 42px;
    padding: 0 20px;
    width: 94%;
}
.ad-detail-half-box .ad-detail-info span, .ad-detail-half-box .ad-detail-info .ad-detail, .ad-detail-half-box .ad-detail-info p {
    color: #888;
    font-size: 14px;
    line-height: 42px;
    text-shadow: none;
}
.ad-detail-half-box .ad-detail-info div > .post-ratings {
    margin-top: 10px;
}
.ad-detail-half-box .ad-detail-info p {
    line-height: 1.4em;
}
.detail-cat {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 35px;
    width: auto !important;
}
.detail-cat a {
    font-size: 18px;
    font-weight: bold;
}
.divider {
    height: 60px;
    width: 100%;
}
.detail-cat .category-icon {
    top: -58px;
}
.ad-detail-half-box tbody {
    width: 100%;
}
.ad-detail-half-box tr {
    float: left;
    padding: 30px;
}
.ad-detail-half-box tr:last-child {
}
.ad-detail-half-box td {
    text-align: left;
}

.ad-detail-info {
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
}

.info-box{
    background: none repeat scroll 0 0 #f8f8f8;
    border: 1px solid #e4e4e4;
    float: left;
    margin-bottom: 30px;
    margin-top: 10px;
    position: relative;
    width: 100%;
    /*min-height: 200px;*/
    padding: 30px;
}

.info-box i.category {
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    height: 40px;
    left: 50%;
    line-height: 40px;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    top: -25px;
    width: 40px;
}

.info-box .sub-cat{
    width: 100%;
    /*margin-top: 20px;*/
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.info-box .ad-detail-info {
    background: none repeat scroll 0 0 #f6f6f6;
    color: #666;
    line-height: 42px;
    padding: 0 20px 0 0;
    width: 100%;
}
.info-box .ad-detail-info .span-left{
    font-weight: bold;
    float: left;
}
.info-box .ad-detail-info .span-left i{
    border: 1px solid #e1e1e1;
    color: #b6b6b6;
    font-size: 16px !important;
    margin-right: 5px;
    padding: 10px 13px;
}
.info-box .ad-detail-info .span-left .fa-map-marker{
    padding-right: 16px;
}
.info-box .ad-detail-info .span-left .fa-money{
    padding-right: 9px;
}
.info-box .ad-detail-info .span-right{
    /*font-weight: bold;*/
    float: right;
}

#ad-address {
    background-color: #ecf0f1;
    border: 1px solid #dfe6e8;
    box-sizing: border-box;
    float: left;
    margin-bottom: 10px;
    width: 100%;
}
#ad-address span {
    color: #484848;
    float: left;
    font-size: 16px;
    padding: 10px 15px;
}
#ad-address span .fa {
    margin-right: 20px;
}
.category-bor{
    height: 3px;
    margin: 12px auto 8px;
    width: 55px;
    background-color: #ed5441;
}
.item-title{
    font-size: 16px;
    line-height: 1.8;
}
.list-switcher{
    font-size: 14px;
    position: relative;
    top: 12px;
}
.list-switcher .selected{
    color:red;
}
.map-switcher .selected{
    color:red;
}

.featured-list-switcher{
    font-size: 14px;
}
.featured-list-switcher .selected{
    color:red;
}

.content-pane{
    background: none repeat scroll 0 0 #fff;
    /*float: left;*/

    margin-top: 4px;
    overflow: auto;
    padding: 0;
    width: 100%;
    height: 450px;
}

.left-pane-data{

}

.left-pane-data ul.listing-details{
    border-top: 1px solid #f1f1f1;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.left-pane-data ul.listing-details li{
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    font: 11px Arial;
    list-style: none outside none;
    margin-left: 0;
    padding: 0 10px 10px;
    position: relative;
    width: 100%;
}
.left-pane-data ul.listing-details li:hover{
    background-color: #f1f1f1;
}
.left-pane-data ul.listing-details li .property-name{
    color: #333;
    display: block;
    font-weight: bold;
    overflow: hidden;
    padding-top: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.left-pane-data ul.listing-details li .property-img{
    height: 40px;
    margin-right: 10px;
    position: relative;
    width: 40px;
}

.left-pane-data ul.listing-details li .property-info{
    /*height: 40px;*/
    /*margin-right: 10px;*/
    position: relative;
    width: 40px;
    width: 120px;
}

.floatLeft {
    float: left;
}

.clearFix:after {
    clear: both;
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}
.clearFix {
    display: inline-block;
}

.map-grid{
    width: 275px;
}
.contact-input{
    margin: 3px 5px 0 0 !important;
}

.detail-slier img{
    width: auto;
    /*height: 550px !important;*/
    max-height: 550px;
}
.agent-holder{
  border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.blog-holder .post-thumb{
    width: 170px !important;
    float: left;
    margin: 0 10px 10px 0;
}
.blog-holder{
    text-align: left;
}


.marker-label,
.marker-icon {
    z-index: 99;
    position: absolute;
    display: block;
    margin-top: -37px;
    margin-left: -14px;
    width: 30px;
    height: 30px;
    font-size: 24px !important;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
}

.package-box-info {
    min-height: 220px!important;
}

.alignLeft{
    text-align: left;
}

/*Tooltip*/
/* This is the default Tooltipster theme (feel free to modify or duplicate and create multiple themes!): */
.tooltipster-default {
    border-radius: 5px;
    border: 2px solid #000;
    background: #4c4c4c;
    color: #fff;
}

/* Use this next selector to style things like font-size and line-height: */
.tooltipster-default .tooltipster-content {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 10px;
    overflow: hidden;
}

/* This next selector defines the color of the border on the outside of the arrow. This will automatically match the color and size of the border set on the main tooltip styles. Set display: none; if you would like a border around the tooltip but no border around the arrow */
.tooltipster-default .tooltipster-arrow .tooltipster-arrow-border {
    /* border-color: ... !important; */
}


/* If you're using the icon option, use this next selector to style them */
.tooltipster-icon {
    cursor: help;
    margin-left: 4px;
}








/* This is the base styling required to make all Tooltipsters work */
.tooltipster-base {
    padding: 0;
    font-size: 0;
    line-height: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999999;
    pointer-events: none;
    width: auto;
    overflow: visible;
}
.tooltipster-base .tooltipster-content {
    overflow: hidden;
}


/* These next classes handle the styles for the little arrow attached to the tooltip. By default, the arrow will inherit the same colors and border as what is set on the main tooltip itself. */
.tooltipster-arrow {
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.tooltipster-arrow span, .tooltipster-arrow-border {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
}
.tooltipster-arrow-top span, .tooltipster-arrow-top-right span, .tooltipster-arrow-top-left span {
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-top: 8px solid;
    bottom: -7px;
}
.tooltipster-arrow-top .tooltipster-arrow-border, .tooltipster-arrow-top-right .tooltipster-arrow-border, .tooltipster-arrow-top-left .tooltipster-arrow-border {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-top: 9px solid;
    bottom: -7px;
}

.tooltipster-arrow-bottom span, .tooltipster-arrow-bottom-right span, .tooltipster-arrow-bottom-left span {
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    border-bottom: 8px solid;
    top: -7px;
}
.tooltipster-arrow-bottom .tooltipster-arrow-border, .tooltipster-arrow-bottom-right .tooltipster-arrow-border, .tooltipster-arrow-bottom-left .tooltipster-arrow-border {
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    border-bottom: 9px solid;
    top: -7px;
}
.tooltipster-arrow-top span, .tooltipster-arrow-top .tooltipster-arrow-border, .tooltipster-arrow-bottom span, .tooltipster-arrow-bottom .tooltipster-arrow-border {
    left: 0;
    right: 0;
    margin: 0 auto;
}
.tooltipster-arrow-top-left span, .tooltipster-arrow-bottom-left span {
    left: 6px;
}
.tooltipster-arrow-top-left .tooltipster-arrow-border, .tooltipster-arrow-bottom-left .tooltipster-arrow-border {
    left: 5px;
}
.tooltipster-arrow-top-right span,  .tooltipster-arrow-bottom-right span {
    right: 6px;
}
.tooltipster-arrow-top-right .tooltipster-arrow-border, .tooltipster-arrow-bottom-right .tooltipster-arrow-border {
    right: 5px;
}
.tooltipster-arrow-left span, .tooltipster-arrow-left .tooltipster-arrow-border {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-left: 8px solid;
    top: 50%;
    margin-top: -7px;
    right: -7px;
}
.tooltipster-arrow-left .tooltipster-arrow-border {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-left: 9px solid;
    margin-top: -8px;
}
.tooltipster-arrow-right span, .tooltipster-arrow-right .tooltipster-arrow-border {
    border-top: 8px solid transparent !important;
    border-bottom: 8px solid transparent !important;
    border-right: 8px solid;
    top: 50%;
    margin-top: -7px;
    left: -7px;
}
.tooltipster-arrow-right .tooltipster-arrow-border {
    border-top: 9px solid transparent !important;
    border-bottom: 9px solid transparent !important;
    border-right: 9px solid;
    margin-top: -8px;
}


/* Some CSS magic for the awesome animations - feel free to make your own custom animations and reference it in your Tooltipster settings! */

.tooltipster-fade {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;
}
.tooltipster-fade-show {
    opacity: 1;
}

.tooltipster-grow {
    -webkit-transform: scale(0,0);
    -moz-transform: scale(0,0);
    -o-transform: scale(0,0);
    -ms-transform: scale(0,0);
    transform: scale(0,0);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-backface-visibility: hidden;
}
.tooltipster-grow-show {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -o-transform: scale(1,1);
    -ms-transform: scale(1,1);
    transform: scale(1,1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

.tooltipster-swing {
    opacity: 0;
    -webkit-transform: rotateZ(4deg);
    -moz-transform: rotateZ(4deg);
    -o-transform: rotateZ(4deg);
    -ms-transform: rotateZ(4deg);
    transform: rotateZ(4deg);
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
}
.tooltipster-swing-show {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
    -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    -moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    -ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    -o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
    transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

.tooltipster-fall {
    top: 0;
    -webkit-transition-property: top;
    -moz-transition-property: top;
    -o-transition-property: top;
    -ms-transition-property: top;
    transition-property: top;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall-show {
}
.tooltipster-fall.tooltipster-dying {
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    -ms-transition-property: all;
    transition-property: all;
    top: 0px !important;
    opacity: 0;
}

.tooltipster-slide {
    left: -40px;
    -webkit-transition-property: left;
    -moz-transition-property: left;
    -o-transition-property: left;
    -ms-transition-property: left;
    transition-property: left;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-slide-show {
}
.tooltipster-slide.tooltipster-dying {
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    -ms-transition-property: all;
    transition-property: all;
    left: 0px !important;
    opacity: 0;
}


/* CSS transition for when contenting is changing in a tooltip that is still open. The only properties that will NOT transition are: width, height, top, and left */
.tooltipster-content-changing {
    opacity: 0.5;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

.login-reg-form{
    margin-bottom: 0px;
}
.checkbox label{
    padding-left: 0px;
}
.radio label{
    padding-left: 0px;
}
.contact-captcha span{
    float:left;margin-top:7px
}
.contact-captcha input{
    width:60px;float:left;margin-left:10px;
}
@media (max-width:982px){
    .main-container{
        width: 100%;
    }
}
@media (min-width:1000px) and (max-width:1200px) {
    .hot-tag{
        top:-150px !important;
    }
}
.ad-detail-info .span-left i{
    color: #fff !important;
    width: 45px !important;

}
.location-icon{
    padding: 10px 0 10px 17px !important;
    background-color:#96C85E !important; 
}
.phone-icon{
    padding: 10px 0 10px 15px !important;
    background-color:#ED5441 !important; 
}
.clock-icon{
    padding: 10px 0 10px 15px !important;
    background-color:#2FBBCF !important; 
}
.price-icon{
    background-color:#FFAA2A !important; 
}
.view-icon{
    background-color:#5D4B59 !important; 
}
.bookmark-icon{
    padding: 10px 0 10px 15px !important;
    background-color:#FF552A !important; 
}
.info-subtitle{
    border-bottom: 1px solid #ccc;
}
.tags a{
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
}
.share-links{
    margin-top: 20px;min-height: 36px;padding-bottom: 20px; text-align: center;
}
.ajax-loading{
    width: 100%;
    text-align: center;
    display: none;
}
.ajax-loading img{
    width:25px !important;
}

.min-height-default{
    min-height:400px;
}

.max-width-maximum{
    max-width: 100%;
}

.agent-form-question{
    float:left;margin-top:7px;
}
.agent-form-answer-input{
    width:60px;float:left;margin-left:10px;
}

.clear-top-margin{
    clear:both;margin-top:10px;
}

.bold-font{
    font-weight:bold
}

.bank-transfer-ins{
    border-bottom:1px solid #aaa;font-weight:bold;font-size:14px;padding:0 0 5px 5px;
}

.gallery-img-list{
    margin:10px 10px 0 0;overflow:hidden;
}

.gallery-img-list img{
    height: 100%;
}
.gallery-upload-instruction{
    font-size:14px;font-style: italic;
}

.align-centre{
    text-align:center
}

.multiple-uploads{
    list-style: none;
    margin:0;
    padding: 0px;
}
.multiple-uploads li{
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    cursor: move;
}
.multiple-uploads .add-image{
    border: 3px dashed #aaa;
    height: 100px;
    padding-top: 10%;
    text-align: center;
    width: 100px;
    cursor: pointer !important;
    font-size: 65px;
    color: #aaa;
}
.multiple-uploads .add-image:hover{
    border: 3px dashed #78a;
    color: #78a;
}

.multiple-uploads .remove-image{
    color: red;
    cursor: pointer;
    float: right;
    font-size: 17px;
    font-weight: bold;
    left: -6px;
    position: relative;
    top: -102px;
    width: 10px;
}
#form-map{
    background-color: #e5e3df;
    height: 300px;
    width: 100%;
}
#form-map img { max-width: none; }

.price-range-amount-view{
    border: 0 none; color: #f6931f; font-weight: bold;
}
.price-range-slider{
    margin-top: 6px;
    margin-left: 28px;
}

.find-my-location{
    margin-top: 0 !important;
    float: left;
}
.progress-bar-span{
    display: block;height: 22px;margin: 2px;padding: 2px;
}

.page-sidebar{
    min-height:300px;margin-top:20px;
}
.featured-badge{
    position: absolute;
    right: 2px;
    text-align: center;
    top: -185px;
    z-index: 20;
}
.hot-tag-list {
    float: right;
    position: absolute;
    right: 7px;
    top: 12px;
    background-color: #ffffff;
    border: 3px solid #bada55;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: 30px;
    line-height: 23px;
    text-align: center;
    width: 30px;
    z-index: 20;
}
.hot-tag-list i{
    color: #ff7430;
}

.category-box {
    margin-top: 30px;
}
.category-box .category-header {
    height: 60px;
    margin-bottom: 0;
    position: relative;
}
.category-thumb-img img{
    margin-bottom: 10px;
}
.category-thumb-img{
    max-width: 300px;
    overflow: hidden;
}
.category-box .category-header .category-icon {
    height: 60px;
    line-height: 60px;
    margin-right: 2px;
    position: absolute;
    text-align: center;
    width: 60px;
}
.category-box .category-header .category-icon:after {
    border: 1px solid #fff;
    content: "";
    height: 60px;
    position: absolute;
    right: 0;
}
.category-box .category-header .category-icon i {
    color: #fff;
    font-size: 2em;
    margin: 0;
    transition: all 0.2s ease-in-out 0s;
    vertical-align: middle;
}
.category-box .category-header .category-title {
    height: 60px;
    padding-left: 68px;
    padding-top: 23px;
    width: 100%;
}
.category-box .category-header .category-title h5 {
    color: #fff;
    float: left;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
}
.category-box .category-header .category-title strong {
    color: #fff;
    float: right;
}
.category-box .category-header:hover .category-icon i {
    font-size: 3em;
}
.category-box .category-header:hover .category-title {
    background: none repeat scroll 0 0 #40555c;
    transition: all 0.2s ease-in-out 0s;
}

.category-box .advertising .category-icon, .category-box .advertising .category-title, .category-box .advertising.category-icon-square {
    background: none repeat scroll 0 0 #32beeb;
}

.list-6 li span{
    display: inline-block;
    font-weight: 600;
}
.review-panel .user-img{
    border-radius: 50%;
    max-width: 100px;
}
.review-panel .user-rating{
    height: 20px;
    width: auto;
}
.review-panel .rating-input{
    list-style: none;
    margin: 0;
    padding: 0;
}
.review-panel .rating-input li{
    font-size: 32px;
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 2px;
    cursor: pointer;
}
.review-panel .rating-input .full-star{
   
}

.stars {
    /*float: left;*/
}
.stars-sidebar{
    float: left;
}
.stars .fa {
    color: #8d8d8d;
    font-size: 16px;
    margin-right: 2px;
    opacity: 0.3;
    transition: all 0.4s ease 0s;
}
.stars .fa.active {
    color: #ff513f;
    opacity: 1;
}

.active-star-color{
    color: #ff513f;
    opacity: 1;
}
.remove_field {
  color: red;
  float: right;
  font-weight: bold;
  position: relative;
  right: 5px;
  top: -33px;
}
.image-style-one img{
    border-radius: 0px;
}
.image-style-one{
    margin-top: 2px;
}
#reviews-holder{
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.business-logo img{
    max-height: 70px;
}
.logo-upload-button{
    background: none repeat scroll 0 0 #222;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 37px;
    opacity: 0.7;
    padding-top: 8px;
    text-align: center;
    margin-top: 10px;   
    z-index: 5;
}
.business-logo-detail img{
    max-height: 60px;
    margin-right: 20px;
}
.business-logo-detail{
}
.business-title-detail{
    line-height: 56px;
}
.sidebar .gallery .thumb{
    height: 60px;
    overflow: hidden;
    border:3px solid #eee;
    border-radius: 3px;
    float: left;
    margin: 0 5px 5px 0;
}
.team-two img{
    max-width: 100px;
}
.navy > ul > .open > ul{
    display: block !important;
}

.header-2 .navy>ul>li>a {
    padding: 21px 12px!important;
}

.category-item ul {
  padding: 0;
}
.category-item li {
  float: left !important;
  font-size: 14px !important;
  line-height: 11px !important;
  list-style: outside none none;
  margin-top: 11px;
  text-align: center;
  width: 100%;
}
.category-item {
  border: 1px solid #e1e1e1;
  float: left;
  height: 245px;
  margin-bottom: 50px;
  padding: 3px;
  position: relative;
}
.category-item-cat {
    border: 1px solid #e1e1e1;
    float: left;
    margin-bottom: 50px;
    padding: 3px;
    position: relative;
}
.widget-content h4{
    word-wrap:break-word;
}
.item-title{
    word-wrap:break-word;    
}
.image-style-one img{
    width: 100%;
}
.my-plane {
  background: rgba(0, 0, 0, 0)  no-repeat scroll 0 0 / 100% 100%;
  display: inline-block;
  height: 32px;
  width: 32px;
}
#dir-panel{
    max-height: 200px;
    overflow: auto;
}
.adp-text{
}
.adp-placemark td{
    padding: 5px 10px;
}
#cookiescript-popup{
  background-color: #111111;
  border-radius: 5px;
  bottom: 20px;
  box-shadow: 0 0 8px #000000;
  color: #ffffff;
  font-family: Arial,sans-serif;
  font-size: 13px;
  font-weight: normal;
  right: 20px;
  line-height: 20px;
  opacity: 1;
  padding: 15px;
  position: fixed;
  text-align: left;
  width: 300px;
  z-index: 999999;

}
#cookiescript-wrapper{
  margin: 0px 10px;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: Arial,sans-serif;
  line-height: 23px;
  letter-spacing: normal;

}


/* Offers */
.prise-box{max-width:300px; width: 100%;float:left;border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;box-shadow: rgba(158,158,158,.61) 0 2px 5px 0;margin-bottom:10px;}
.prise-box img{width:100%;border-radius:10px 10px 0 0;-webkit-border-radius:10px 10px 0 0;-moz-border-radius:10px 10px 0 0;}
.info-this{    float: left;   width: 100%;   background:#ececec;    padding: 5px;}
.left-box{width: 70%;float:left;}

/* */
.filter-box{width: 100%;float:left;border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;box-shadow: rgba(158,158,158,.61) 0 2px 5px 0;margin-bottom:10px;}


.left-box h5 a{
    background-color: #ececec;
    padding: 4px 1px;
    margin-top: 6px;
    display: inline-block;
    font-size: 15px;
    line-height: 30px;
    color: #0073aa;
    font-family: Open Sans,sans-serif;
    font-weight: 600;
    margin: 2px 0;
    padding: 0;
}

.left-box h5 {
    font-size: 15.5px;
}

.left-box h2{
    font-weight: normal;
    margin:0px;
    padding:0px;
    color: #000;
    text-align: left;
    font-style:italic;
    font-size: 29px;
    margin: 0;
    margin-bottom: 0;
    margin-top: -10px;
}

.left-box h3{
    font-weight: normal;
    padding:0px;
    padding-top:5px;
    color: #8d0104;
    font-style:italic;
    margin: 0;
    margin-bottom: 0;
}
.right-box-up{width: 20%;float:right;}
.blue-box span{
    font-size: 12px;
    margin: 4px 5px;
    color: #fffb5f;}
.blue-box h2{margin: 2px 1px;
    font-size: 20px;
    color: #ffffff;
    font-weight: normal;}
.blue-box{
    box-shadow: 1px 1px 4px #797979;
    background-color: #031f5d;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    padding: 5px 4px;
    text-align: center;  
    float: right;
    margin-top: -29px;
    z-index: 999999;
    position: relative;
}


@media(min-width:576px){
   
    .left-box h3{
        font-size: 18px;
    }

    .info-this {
        min-height: 105px;
    }
    .prise-box {
        background:#ececec;
        max-width:170px;
    }

    .offer-image-container{
        height:120px;
    }

    .offer-image{
        max-height:120px;
    }

    .enterprise-image-container{
        height:230px;
    }
    .enterprise-image{
        max-height:230px;
    }

    .enterprise-text-container{
        height:220px;
        width: 200px;
    }
    .generalContent{
        width: 576px;
    }
}
@media(min-width:768px){
    .left-box h3{
        font-size: 22px;
    }
    .blue-box{
        padding: 1px 1px;
        margin-right: -4px;
        max-width:170px;
    }

    .offer-image-container{
        height:120px;
    }

    .offer-image{
        max-height:120px;
    }

    .enterprise-image-container{
        height:230px;
    }
    .enterprise-image{
        max-height:230px;
    }

    .enterprise-text-container{
        height:220px;
        width: 200px;
    }
    .generalContent{
        width: 768px;
    }
}
@media(min-width:992px){
    .left-box h3{
        font-size: 22px;
    }
    .prise-box {
        max-width:170px;
    }

    .offer-image-container{
        height:120px;
    }

    .offer-image{
        max-height:120px;
    }

    .enterprise-image-container{
        height:230px;
    }
    .enterprise-image{
        max-height:230px;
    }

    .enterprise-text-container{
        height:220px;
        width: 200px;
    }
    .generalContent{
        width: 992px;
    }
}
@media(min-width:1200px){
    .left-box h3{
        font-size: 22px;
    }
    .prise-box {
        max-width:170px;
    }

    .offer-image-container{
        height:120px;
    }
    .offer-image{
        max-height:120px;
    }
    .offer-text-container{
        height:200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offer-text-content{
        width: 100%;
    }

    .enterprise-image-container{
        height:230px;
    }
    .enterprise-image{
        max-height:230px;
    }
    .enterprise-text-container{
        height:220px;
        width: 200px;
    }


    .generalContent{
        width: 1150px;
    }
}

.offer-detail-box{width:100%;float:left;border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;box-shadow: rgba(158,158,158,.61) 0 2px 5px 0;padding-top: 10px;padding-bottom: 10px;}
.offer-detail-box img{border-radius:10px 0 0 10px;-webkit-border-radius:10px 0 0 10px;-moz-border-radius:10px 0 0 10px;}
.info-this-detail{float: left;   width: 100%;   background:#ececec;    padding: 10px; }
.info-this-detail p {margin-bottom:0.5rem }

.img-box-4 {
    margin: 5px 0;
    box-sizing: border-box;
    padding-right: 5px!important;
    padding-left: 5px!important;
}

.img-box-4 .img-box-4-item {
    max-width: 260px;
    margin: 10px auto;
    border: 1px solid #9a9a9a;
}

.img-box-4 .img-box-4-content {
    border: 0px;
    border-top: 0;
    padding: 10px 20px 15px;
    position: relative
}

.img-box-4 a .category-fa-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 50px;
    display: inline-block;
    position: absolute;
    top: -25px;
    left: 50%;
    margin-left: -20px;
    font-size: 15px;
    z-index: 20;
}

.img-box-4 h4 a {
    color: #777
}

.img-box-4 .bor {
    width: 30%;
    height: 2px;
    margin: 0px auto 0px
}

.image-style-one {
    z-index: 0;
}

.vertical-center
{
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.checkbox-1x {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
}

.inputFindByText {
    font-size: 13px!important;
    color: rgb(51, 51, 51);
    min-height: 40px;
}

.hiddenRow {
    padding: 0 !important;
}

.card-header{
    padding: 0px!important;
}

.card-body {
    padding: 1.25rem;
    padding-top: 0!important;
    padding-right: 0!important;
    padding-left: 2rem!important;
    background-color: aliceblue!important;
}

.file-upload-easy-crop-container {

}



.headerimage{ background:#000; position:relative;}
.btn-wrapper{ text-align:center; position:absolute; bottom: 0px; margin: 0 auto; width:100%;}


.generalContainer {
    overflow:hidden;
    text-align:center;
}

.container {
    text-align:left;
}

.generalContent {
    max-width: 1150px;
    border: 3px solid #00BD3D;
    margin: 0px;
    display:inline-block;
}


.block-heading-two h3 {

}


.blink {
    -webkit-animation: blink 4s infinite both;
    animation: blink 4s infinite both;
}

@-webkit-keyframes blink {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}
@keyframes blink {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}

