.whatsApp-link{
    position:fixed;
    width:60px;
    height:60px;
    bottom:30px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.whatsApp-icon{
    margin-top:16px;
}

.whatsApp-link:hover {
    color:#FFF;
}